<template>
  <div class="Report">
    <!-- Page header -->
    <div class="PageHeader">
      <Button
        :text="mixWB('BACK')"
        class="Back"
        :path="{ name: 'ResultOverview', params: { screeningID: screeningID } }" />
      <ErrorText
        v-if="hasErrorOnDownloadPdf"
        :text="mixWB('PDF_DOWNLOAD_ERROR_HAPPENED')" />
      <Button
        class="DownloadButton"
        :text="mixWB('PDF_SAVE')"
        :fullWidth="false"
        @button-click="savePDF" />
    </div>

    <MissingThings />

    <div class="PageList">
      <FrontPage />
      <TableOfContents
        :allPagesLoaded="allPagesLoaded"
        :pdf-type="'report'" />
      <GeneralInformation />
      <Units v-if="selectedUnits.length" />
      <Buildings v-else />
      <AnalysisResultsExplanationPage />
      <AnalysisResults :pdf-type="'report'" />
      <FloorPlans v-if="screeningIsFromVersion1160OrNewer" />
      <FloorPlansPre1160 v-else />
      <CategoryList />
      <CategoriesAndTypes v-if="pagesToBeLoaded.analysisOverview" />
      <FinalWords />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'
import MissingThings from '@/components/PDF/Report/MissingThings.vue'
import FrontPage from '@/components/PDF/Report/FrontPage.vue'
import TableOfContents from '@/components/PDF/TableOfContents.vue'
import GeneralInformation from '@/components/PDF/Report/GeneralInformation.vue'
import Units from '@/components/PDF/Report/Units.vue'
import Buildings from '@/components/PDF/Report/Buildings.vue'
import AnalysisResultsExplanationPage from '@/components/PDF/Report/AnalysisResultsExplanationPage.vue'
import AnalysisResults from '@/components/PDF/AnalysisResults/AnalysisResults.vue'
import CategoriesAndTypes from '@/components/PDF/CategoriesAndTypes/CategoriesAndTypes.vue'
import FinalWords from '@/components/PDF/Report/FinalWords.vue'
import EventBus from '@/EventBus'
import FloorPlans from '@/components/PDF/Report/FloorPlans.vue'
import FloorPlansPre1160 from '@/components/PDF/Report/FloorPlansPre1.16.0.vue'
import CategoryList from '@/components/PDF/CategoryList.vue'
import { queryAllByClass } from '@/globals/javascript/_util/find-elements'
import { mixWB } from '@/globals/javascript/_util/mixins'
import { mapGetters } from 'vuex'
import { PDFTracker } from '@/globals/javascript/_util/PDFTracker'
import ErrorText from '@/components/FormElements/ErrorText.vue'

export default {
  name: 'Report',
  data() {
    return {
      assessmentPageCount: 0,
      extraBuildingPageCount: 0,
      extraTestResultPageCount: 0,
      areCategoriesAndTypesLoaded: false,
      isTestResultOverviewLoaded: false,
      pagesToBeLoaded: {
        analysisOverview: false,
        categoriesAndTypes: false,
      },
      hasErrorOnDownloadPdf: false,
    }
  },
  computed: {
    ...mapGetters([
      'selectedUnits',
      'currentScreeningData',
    ]),
    screeningIsFromVersion1160OrNewer() {
      return this.mixCompareVersionsSameOrNewer({
        versionA: this.currentScreeningData.appVersion,
        versionB: '1.16.0',
      })
    },
    allPagesLoaded() {
      const anyPageNotLoaded = Object.keys(this.pagesToBeLoaded).find(
        (key) => !this.pagesToBeLoaded[key],
      )

      return !anyPageNotLoaded
    },
    screeningID() {
      return this.$route.params.screeningID
    },
  },
  methods: {
    savePDF() {
      // For pro v1 we emulate CTRL+P
      window.print()
    },
    onPageLoaded(pageName) {
      this.pagesToBeLoaded[pageName] = true
    },
    onTestResultsExtraPages(number) {
      this.extraTestResultPageCount = number
      this.isTestResultOverviewLoaded = true
      this.updatePageNumbers()
    },
    onBuildingExtraPages(number) {
      this.extraBuildingPageCount = number
    },
    updatePageNumbers() {
      if (!this.areCategoriesAndTypesLoaded || !this.isTestResultOverviewLoaded) {
        return
      }

      const pages = queryAllByClass('AssessmentPage')
      if (!pages.length) {
        setTimeout(() => {
          this.onCategoriesAndTypesDoneLoading()
        }, 100)
        return
      }

      if (pages.length > this.assessmentPageCount) {
        this.assessmentPageCount = pages.length
        setTimeout(() => {
          this.onCategoriesAndTypesDoneLoading()
        }, 1000)
        return
      }

      // Update page number
      pages.forEach((pageNumber, index) => {
        pageNumber.innerHTML = `${
          mixWB('PAGE')
        } | ${
          8 + this.extraBuildingPageCount + this.extraTestResultPageCount + index
        }`
      })
    },
    onReady() {
      PDFTracker.loaded(`report/${ this.screeningID }`)
    },
  },
  components: {
    Button,
    MissingThings,
    FrontPage,
    TableOfContents,
    GeneralInformation,
    Units,
    Buildings,
    AnalysisResultsExplanationPage,
    AnalysisResults,
    FloorPlans,
    FloorPlansPre1160,
    CategoryList,
    CategoriesAndTypes,
    FinalWords,
    ErrorText,
  },
  created() {
    EventBus.$on('page-loaded', this.onPageLoaded)
    EventBus.$on('report-ready', this.onReady)
    PDFTracker.track(`report/${ this.screeningID }`)
  },
  destroyed() {
    EventBus.$off('page-loaded', this.onPageLoaded)
    EventBus.$off('report-ready', this.onReady)
    PDFTracker.unTrack(`report/${ this.screeningID }`)
  },
}
</script>

<style lang="stylus" scoped>
  .Report
    display block

  .PageHeader
    width 794px
    margin 20px auto
    display flex
    justify-content space-between
    .Back
      display inline-block
    .DownloadButton
      width auto

  @media print
    .PageHeader
      display none
</style>

<style lang="stylus">
  .PageReport
    background-color $color_grey_lighter

  .PageList
    *
      font-family 'Montserrat', sans-serif
      font-size 0.75rem
      line-height 1.5
</style>

