<template>
  <div
    id="analysis-results"
    class="AnalysisResultsExplanationPage">
    <PageTag
      :pageName="mixWB('ANALYSIS_RESULTS_EXPLANATION')" />

    <div class="InnerPage js-inner-page">
      <h1
        class="js-headline"
        id="analysis-overview">6. {{ mixWB('ANALYSIS_RESULTS') }}</h1>

      <h2>6.1 {{ mixWB('EXPLANATION_OF_LIMIT_VALUES') }}</h2>

      <p>
        Der er ikke foretaget en opmåling og mængdeberegning af de konstaterede materialer
        i bygningerne. <br/>
        Analyserapporter med angivelse af analysemetoder for alle analyser er vedlagt.
      </p>

      <div class="Table">
        <div class="Row Headline">
          <span class="Name">Grænseværdier</span>
          <span class="Clean">{{ mixWB('Rent affald') }}</span>
          <span class="Contaminated">{{ mixWB('Forurenet affald') }}</span>
          <span class="Hazardous">{{ mixWB('Farligt affald') }}</span>
        </div>
        <div
          class="Row"
          v-for="item in items"
          :key="item.name">
          <span class="Name">{{ item.name }}</span>
          <span class="Clean">{{ item.clean }}</span>
          <span class="Contaminated">{{ item.contaminated }}</span>
          <span class="Hazardous">{{ item.hazardous }}</span>
        </div>
      </div>

      <p>
        Indholdet af bly, zink og kobber summeres, for værdier over 1.000 mg/kg. Hvis den
        akkumulerede værdi er over 2.500 mg/kg klassificeres prøven som farligt affald.
      </p>
      <p>
        Resultaterne af de udførte analyser fremgår af nedenstående tabeller.
      </p>
      <p>
        Hvis koncentrationerne af PCB, KP, PAH, kulbrinter, tungmetaller eller asbest svarer til:
      </p>
      <ul>
        <li>Rent affald, er resultatet fremhævet med grøn markering.</li>
        <li>Forurenet affald, er resultatet fremhævet med gul markering.</li>
        <li>Farligt affald, er resultatet fremhævet med fed skrift og rød markering.</li>
      </ul>
      <p>
        Samtlige prøver for PCB er screenet for klorerede paraffiner. De prøver, hvor den endelig
        klassificering af affald og håndtering er baseret på analyseresultat af
        klorerede paraffiner er indeholdt i nærværende rapport.
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import Footer from '@/components/PDF/Footer.vue'

export default {
  name: 'AnalysisResultsExplanationPage',
  data() {
    return {
      items: [
        {
          name: this.mixWB('SAMPLE_PCB'),
          clean: '< 0,10 mg/kg',
          contaminated: '0,10-50 mg/kg',
          hazardous: '> 50 mg/kg',
        },
        {
          name: this.mixWB('CHLORINATED_PARAFFINS_SHORT_CHAINED'),
          clean: '',
          contaminated: '',
          hazardous: '> 2.500 mg/kg',
        },
        {
          name: this.mixWB('CHLORINATED_PARAFFINS_MEDIUM_CHAINED'),
          clean: '',
          contaminated: '',
          hazardous: '> 2.500 mg/kg',
        },
        {
          name: this.mixWB('LEAD'),
          clean: '< 40 mg/kg',
          contaminated: '40-2.500 mg/kg',
          hazardous: '> 2.500 mg/kg',
        },
        {
          name: this.mixWB('CADMIUM'),
          clean: '< 0,50 mg/kg',
          contaminated: '0,50-1.000 mg/kg',
          hazardous: '> 1.000 mg/kg',
        },
        {
          name: this.mixWB('CHROME'),
          clean: '< 500 mg/kg',
          contaminated: '500-1.000 mg/kg',
          hazardous: '> 1.000 mg/kg',
        },
        {
          name: this.mixWB('COPPER'),
          clean: '< 500 mg/kg',
          contaminated: '500-2.500 mg/kg',
          hazardous: '> 2.500 mg/kg',
        },
        {
          name: this.mixWB('NICKEL'),
          clean: '< 30 mg/kg',
          contaminated: '30-1.000 mg/kg',
          hazardous: '> 1.000 mg/kg',
        },
        {
          name: this.mixWB('ZINC'),
          clean: '< 500 mg/kg',
          contaminated: '500-2.500 mg/kg',
          hazardous: '> 2.500 mg/kg',
        },
        {
          name: this.mixWB('MERCURY'),
          clean: '< 1 mg/kg',
          contaminated: '1-1.000 mg/kg',
          hazardous: '> 1.000 mg/kg',
        },
        {
          name: this.mixWB('SAMPLE_ASBEST'),
          clean: this.mixWB('NOT_PROVEN'),
          contaminated: this.mixWB('PROVEN_NOT_DUSTY'),
          hazardous: this.mixWB('PROVEN_DUSTY'),
        },
        {
          name: this.mixWB('PAH_SUM'),
          clean: '< 3 mg/kg',
          contaminated: '1-1.000 mg/kg',
          hazardous: '> 1.000 mg/kg',
        },
        {
          name: this.mixWB('HYDROCARBONS_SUM'),
          clean: '< 100 mg/kg',
          contaminated: '100-1.000 mg/kg',
          hazardous: '> 1.000 mg/kg',
        },
      ],
    }
  },
  components: {
    PageTag,
    Footer,
  },

}
</script>

<style lang="stylus" scoped>
  .AnalysisResultsExplanationPage
    page()

  .InnerPage
    innerPageReport()

  .Table
    border 1px solid $color_grey_dark
    margin-bottom 20px
    .Row
      display flex
      .Clean
        background-color $color_report_green
      .Contaminated
        background-color $color_report_yellow
      .Hazardous
        background-color $color_report_red
      &:nth-child(even)
        background-color $color_grey_lightest
        .Clean
          background-color $color_report_green_alt
        .Contaminated
          background-color $color_report_yellow_alt
        .Hazardous
          background-color $color_report_red_alt
      span
        font-size 0.75rem
        padding 5px 10px
        min-height 24px
        display flex
        align-items center
      &.Headline
        background-color $color_grey_dark
        span
          font-size 1rem
          &.Name
            color #fff
            font-size 1.125rem
    .Name
      flex-grow 2
      max-width 222px
    .Clean,
    .Contaminated,
    .Hazardous
      display block
      width 140px
      margin-left 0px
    .Contaminated
      width 150px

  ul
    margin-left 20px
    margin-bottom 15px
</style>
