<template>
  <div
    id="floor-plans"
    class="FloorPlans">
    <PageTag
      :pageName="mixWB('FLOOR_PLANS')" />
    <PageActions
      :actions="[
        { text: mixWB('UPLOAD_FLOOR_PLAN'), id: 'upload-floor-plan' }
      ]"
      @upload-floor-plan="onUploadFloorPlan" />

    <div
      class="InnerPage js-inner-page">

      <h1
        class="js-headline"
        id="floor-plans">7. {{ mixWB('FLOOR_PLANS') }}</h1>

      <!-- No image -->
      <div
        v-if="!overviewFloorPlanImages.length"
        class="NoImage">
        <Button
          :text="mixWB('UPLOAD_FLOOR_PLAN')"
          :fullWidth="false"
          @button-click="onUploadFloorPlan" />
      </div>

      <!-- Has image -->
      <div
        v-else
        class="Image">
        <MultiImage
          :image="overviewFloorPlanImages[0]"
          size="base"
          :keepSquare="false" />
      </div>
    </div>

    <Footer />
    <!-- Hidden upload input -->
    <div class="HiddenUploadField">
      <input
        ref="HiddenUploadField"
        type="file"
        accept="image/*"
        @change="onImagesSelected" />
    </div>
  </div>
</template>

<script>
import PageTag from '@/components/PDF/PageTag.vue'
import Footer from '@/components/PDF/Footer.vue'
import Button from '@/components/Buttons/Button.vue'
import PageActions from '@/components/PDF/PageActions.vue'
import MultiImage from '@/components/Images/MultiImage'
import { mapGetters } from 'vuex'
import { maxImageSize, prepareImage } from '@/globals/javascript/_util/images'

export default {
  name: 'FloorPlansPre1160',
  data() {
    return {
      maxImageSize,
      prepareImage,
    }
  },
  computed: {
    ...mapGetters([
      'addressImages',
      'addressImagesLoaded',
      'imageUploadFolder',
    ]),
    overviewFloorPlanImages() {
      if (
        !this.addressImages
        || !this.addressImages.overview
        || !this.addressImages.overview.floorPlans) {
        return []
      }

      return this.addressImages.overview.floorPlans
    },
    basePath() {
      return `${ this.imageUploadFolder }AddressImages/Overview/`
    },
  },
  methods: {
    onUploadFloorPlan() {
      this.$refs.HiddenUploadField.click()
    },
    onImagesSelected(event) {
      const { files } = event.target

      if (!files || !files[0]) {
        return
      }

      files.forEach((file) => {
        this.onNewFile(file)
      })
    },
    onNewFile(file) {
      // Create image object
      const imageObject = this.prepareImage({
        basePath: this.basePath,
        file,
        postFix: 'floor-plan',
        minSize: 800,
      })

      // Upload image
      this.$store.dispatch('uploadImage', { imageObject, file, place: this.place })

      // Save image ref
      this.$store.dispatch('setAddressImages', {
        imageList: [imageObject],
        place: 'overview',
        imageType: 'floorPlans',
      })
    },
  },
  components: {
    PageTag,
    Footer,
    Button,
    PageActions,
    MultiImage,
  },

}
</script>

<style lang="stylus" scoped>
  .FloorPlans
    page()

  .InnerPage
    innerPageReport()

  .NoImage
    box(100%)
    flex-center-children()
    padding-bottom 200px
    background-color red

  .Image
    height 100%
    padding-bottom 100px

  .HiddenUploadField
    box(0px)
    overflow hidden

</style>
